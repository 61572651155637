import React from 'react'
import {Outlet} from 'react-router-dom'
import Sidebar from './Sidebar'
import MobileNavbar from './MobileNavbar'
import Footer from './Footer'
import { useMediaQuery } from "react-responsive";


const Layout = ({children}) => {
  let isTabletMid = useMediaQuery({ query: "(max-width: 820px), (max-height: 500px)" });
  return (
    <>
    {!isTabletMid && (
      <div className="min-h-screen grid grid-cols-[auto,1fr] relative">
        <Sidebar/>
        <div class="flex flex-col bg-gray-100 overflow-y-auto" style={{ position: "relative" }}>
            <Outlet/>
          <Footer/>
        </div>
      </div>
    )}

    {isTabletMid && (
      <div className="min-h-screen">
        <MobileNavbar/>
        <div class="bg-gray-100">
            <Outlet/>
        <Footer/>
        </div>
      </div>
    )}
    </>
  )
}

export default Layout