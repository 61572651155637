import React from 'react'

const BlogDetailSideCard = ({relatedBlogs}) => {
  return (
    <div className="flex flex-col justify-center mb-8 px-3 lg:px-12 py-6">
        <h2 className="text-black text-md font-bold lg:text-lg pt-6 pb-2">Rubrik Terkait</h2>
        <div className="space-y-4 pt-2">
              {relatedBlogs.map((relatedBlog) => (
                <div key={relatedBlog.id} className="flex flex-col">
                  <a href={`/rubrik/${relatedBlog.id}`} className="text-blue-500 hover:text-blue-700">
                    <h4 className="font-semibold">{relatedBlog.title}</h4>
                    <p>{relatedBlog.excerpt}</p>
                  </a>
                </div>
              ))}
        </div>
    </div>
  )
}

export default BlogDetailSideCard