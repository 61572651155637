import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import BlogDetailSideCard from "../components/BlogDetailSideCard";
import { useMediaQuery } from "react-responsive";
import MetaDecorator from "../components/MetaDecorator";

const BlogDetail = () => {
  let isMobile = useMediaQuery({ query: "(max-width: 480px), (max-height: 500px)" });
  let isTabletMid = useMediaQuery({ query: "(max-width: 820px), (max-height: 500px)" });
  const { id } = useParams();
  const [blog, setBlog] = useState({});
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);
  const [previousBlog, setPreviousBlog] = useState(null);
  const [nextBlog, setNextBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://api2.viaductpress.id/rubrik/${id}`);
        setBlog(response.data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };
    const fetchAllBlogs = async () => {
      try {
        const response = await axios.get("https://api2.viaductpress.id/rubrik");
        setAllBlogs(response.data);
      } catch (error) {
        console.error("Error fetching all blogs:", error);
      }
    };

    fetchBlog();
    fetchAllBlogs();
  },[id]);
  
  useEffect(() => {
    if (blog.categories) {
      const related = allBlogs.filter((b) => {
        if (b.id === blog.id) return false; // Exclude the current blog
      
        // Split categories into arrays
        const blogCategories = b.categories.split(",").map(category => category.trim().replace(/"/g, ""));
        const currentBlogCategories = blog.categories.split(",").map(category => category.trim().replace(/"/g, ""));
        // Check if there is any intersection between current blog's categories and other blog's categories
        const hasCommonCategory = blogCategories.some(c =>
          currentBlogCategories.includes(c)
        );
      
        return hasCommonCategory;
      });
      const sortedBlogs = related
      .slice() // Create a copy to avoid mutating the original array
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      
      const currentBlogIndex = blog.id;
      
      const previous = sortedBlogs.filter(b => new Date(b.createdAt) < new Date(blog.createdAt))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
      const next = sortedBlogs.filter(b => new Date(b.createdAt) > new Date(blog.createdAt))
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))[0];

      setPreviousBlog(previous);  // Previous blog
      setNextBlog(next); // Next blog

      setRelatedBlogs(sortedBlogs.slice(0, 3)); // Display up to 3 related blogs
    }
  }, [blog, allBlogs]);

  const parseCategories = (categories) => {
    if (!categories) return [];
    return categories
      .split(",")
      .map((category) => category.trim().replace(/"/g, ""));
  };

  const styleContent = (content) => {
    if (!content) return "";
    return DOMPurify.sanitize(content)
      .replace(/\n/g, "<br />")
      .replace(/\u00a0/g, "<br />")
  };

  return (
    <>
    <MetaDecorator title={blog.title+" - Viaduct Press"} description={blog.content?.replace(/<[^>]*>/g, "")?.slice(0,156)+"..."}/>
      <div className="bg-gray-200 py-12">
        <div className="flex flex-col justify-center items-center">
          {/* Render category buttons */}
          <div className="flex flex-wrap gap-2 items-center justify-center rounded-full border border-zinc-600 px-2 py-[6px]">
            {parseCategories(blog.categories).map((category, index) => (
              <a
                key={index}
                className="bg-black text-white px-4 py-2 text-sm hover:bg-zinc-800 duration-300"
                href={`/rubrik?category=${category}`}
              >
                {category}
              </a>
            ))}
          </div>
          <div className="relative text-center mt-3 text-5xl font-medium">
            <h1 className="relative text-black font-bold pb-3 px-5 leading-[65px] z-10 isolate">
              {blog.title}
            </h1>
          </div>
          <p className="text-black pb-3 text-lg"><b>{blog.authorName}</b> pada {new Date(blog.createdAt).toLocaleDateString('id', {month: 'long', day: '2-digit', year: 'numeric'})}</p>
        </div>
      </div>
      {/* Blog Content Section */}
      <div className="grid grid-cols-1 gap-y-8 px-4 
                sm:gap-y-4 
                md:grid-cols-2 md:gap-x-2 
                lg:grid-cols-8 pt-10 md:mt-0 text-black">
        {!isTabletMid && (<div className="col"></div>)}
        <div className="col-span-2 md:col-span-2 lg:col-span-4 mx-auto px-4">
          {/* Image Section */}
          <div className="flex justify-center mb-8">
            <img
              src={blog.coverImage}
              alt="Blog"
              className="w-full h-96 object-cover rounded-lg shadow-md"
              id="mainImage"
            />
          </div>

          {/* Content Section */}
          <div className="mb-8">
            <div className='text-md sm:text-lg pt-5 line-break' dangerouslySetInnerHTML={{ __html: styleContent(blog.content) }}></div>
          </div>
        </div>
        {/* Navigation Section */}
        <div className="col-span-3">
          <BlogDetailSideCard relatedBlogs={relatedBlogs}/>
        </div>
      </div>
      {/* Previous & Next Blog Navigation */}
      <div className="grid grid-cols-3 px-6 pt-6 pb-4">
          <div className="col flex justify-center items-start sm:items-center">
            {previousBlog && (
              <a href={`/rubrik/${previousBlog.id}`} className="flex flex-rows h-20 gap-x-5 items-start sm:items-center">
                <p className="text-blue-500 hover:text-blue-700 text-right">&larr; Previous<br></br>{previousBlog.title}</p>
                {!isMobile && (<img src={previousBlog.coverImage} alt="Previous Blog" className="h-20 w-20 aspect-square" />)}
              </a>
            )}
          </div>
          <div className="col"></div>
          <div className="col flex justify-center items-start sm:items-center">
            {nextBlog && (
              <a href={`/rubrik/${nextBlog.id}`} className="flex flex-rows h-20 gap-x-5 items-start sm:items-center">
                {!isMobile && (<img src={nextBlog.coverImage} alt="Next Blog" className="h-20 w-20 aspect-square"/>)}
                <p className="text-blue-500 hover:text-blue-700 text-left">Next &rarr;<br></br>{nextBlog.title}</p>
              </a>
            )}
          </div>
      </div>
    </>
  );
};

export default BlogDetail;
