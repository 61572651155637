import React, { useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import axios from "axios"
import MetaDecorator from "../components/MetaDecorator";

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://api2.viaductpress.id/produk/${id}`);
        setProduct(response.data);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [id]);
  
  return (
      <>
      <MetaDecorator title={product.title+" - Viaduct Press"} description={"Dapatkan "+product.title+" karya Viaduct Press, yang bukan hanya informatif, tapi juga menarik."}/>
      <div class="bg-gray-100">
        <div class="container mx-auto px-4 py-8">
          <div class="flex flex-wrap -mx-4">
            <div class="w-full md:w-72 px-4 mb-8">
              <img src={product.coverImage} alt="Product"
                class="w-full h-auto rounded-lg shadow-md mb-4" id="mainImage" />
            </div>
            <div class="w-full md:w-1/2 px-4">
              <h2 class="text-3xl font-bold mb-2">{product.title}</h2>
              <p class="text-lg font-bold mb-2">Category: <u>{product.category}</u></p>
              <p class="text-gray-700 mb-6">{product.description}<br></br>Produk ini bisa kalian akses melalui link dibawah ini,</p>
              <a class="text-gray-700 mb-6 hover:text-blue-500" href={product.url}>{product.title}</a>
            </div>
          </div>
        </div>
      </div></>
)
}

export default ProductDetail