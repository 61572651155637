import React from "react";

const ProductCard = ({id, title, image}) => {
  return (
    <div class="relative flex w-full flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md item">
        <img class="h-56 w-full object-cover" src={image} alt="Product Image"/>
      <div class="mt-4 px-5 pb-1">
        <div class="font-bold text-xl mb-2">{title}</div>
      </div>
      <div class="px-6 pb-4">
        <a className="flex items-center justify-center bg-black px-8 py-3 text-center text-sm font-medium text-white hover:bg-neutral-700" href={"/produk/"+id}>Lihat</a>
      </div>
  </div>

  
  );
};

export default ProductCard;
