import React from "react";
import { FaFacebook, FaInstagram, FaTiktok, FaPhoneAlt, FaMapMarkerAlt, FaBuilding } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import MetaDecorator from "../components/MetaDecorator";

const About = () => {
  return (
    <>
    <MetaDecorator title="Tentang Kami - Viaduct Press" description="Viaduct adalah organisasi otonom pers mahasiswa Fakultas Hukum Universitas Katolik Atma Jaya yang menerbitkan produk-produk jurnalistik."/>
    <div className="p-8 max-w-4xl mx-auto">
      {/* Main Description */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-center mb-4">Tentang Kami</h1>
        <p className="text-justify text-gray-700">
          Viaduct merupakan organisasi otonom pers mahasiswa (persma) Fakultas Hukum Universitas Katolik Atma Jaya dengan
          kegiatan utama menerbitkan produk-produk jurnalistik, yang bekerja sesuai dengan kaidah-kaidah jurnalistik. Juga
          berkegiatan secara eksternal kampus, internal organisasi, juga terhadap kampus dan Fakultas Hukum Universitas
          Katolik Atma Jaya.
        </p>
      </div>

      {/* Contact Details */}
      <div className="space-y-6">
        {/* Corporate Info */}
        <div className="flex items-center space-x-4">
          <FaBuilding className="text-black text-xl" />
          <span className="text-gray-700 font-medium">
            Organisasi Pers Mahasiswa FH Unika Atma Jaya Jakarta
          </span>
        </div>

        {/* Location */}
        <div className="flex items-center space-x-4">
          <FaMapMarkerAlt className="text-black text-xl" />
          <span className="text-gray-700 font-medium">
            Gedung K1 – Jl. Jendral Sudirman Kav 51, Jakarta, Indonesia
          </span>
        </div>

        {/* Phone Contact */}
        <div className="flex items-center space-x-4">
          <FaPhoneAlt className="text-black text-xl" />
          <span className="text-gray-700 font-medium">
            Narahubung/ Iklan: I Gede Oka Kertiyasa (0813-8439-0900)
          </span>
        </div>

        {/* Social Media */}
        <div>
          <h2 className="text-lg font-medium mb-2">Media Sosial Kami:</h2>
          <div className="flex space-x-6">
            {/* Twitter */}
            <a
              href="https://twitter.com/viaductpress"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:text-blue-700 text-xl"
              title="Twitter"
            >
              <FaXTwitter />
            </a>
            {/* Facebook */}
            <a
              href="https://facebook.com/ViaductPersMahasiswa"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:text-blue-900 text-xl"
              title="Facebook"
            >
              <FaFacebook />
            </a>
            {/* Instagram */}
            <a
              href="https://instagram.com/viaductpress"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:text-pink-700 text-xl"
              title="Instagram"
            >
              <FaInstagram />
            </a>
            {/* TikTok */}
            <a
              href="https://tiktok.com/viaductpress"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:text-gray-700 text-xl"
              title="TikTok"
            >
              <FaTiktok />
            </a>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default About;
