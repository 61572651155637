import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ProductCard from "../components/ProductCard"; // A reusable component to display individual products.
import axios from "axios";
import MetaDecorator from "../components/MetaDecorator";

const Product = () => {
  const [products, setProducts] = useState([]); // Full product list
  const [filteredProducts, setFilteredProducts] = useState([]); // Products after filtering
  const [searchTerm, setSearchTerm] = useState(""); // Search input
  const [currentPage, setCurrentPage] = useState(1); // Current pagination page
  const productsPerPage = 6; // Number of products per page
  const [searchParams, setSearchParams] = useSearchParams(); // For handling query parameters

  // Extract category from query params
  const category = searchParams.get("category") || "";

  // Fetch all products from the backend
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("https://api2.viaductpress.id/produk");
        setProducts(response.data);
        setFilteredProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Apply search and filter whenever `category` or `searchTerm` changes
  useEffect(() => {
    let updatedProducts = products;

    // Filter by category
    if (category) {
      updatedProducts = updatedProducts.filter(
        (product) => product.category === category
      );
    }

    // Search by name/description
    if (searchTerm) {
      updatedProducts = updatedProducts.filter(
        (product) =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredProducts(updatedProducts);
    setCurrentPage(1); // Reset to the first page on filter/search
  }, [category, searchTerm, products]);

  // Pagination Logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Render the pagination buttons with a limited number of visible buttons
  const renderPagination = () => {
    const maxVisibleButtons = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxVisibleButtons / 2));
    const endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);

    const paginationButtons = [];
    for (let i = startPage; i <= endPage; i++) {
      paginationButtons.push(
        <button
          key={i}
          className={`px-3 py-1 rounded ${
            i === currentPage
              ? "bg-black text-white"
              : "bg-gray-200 text-gray-800"
          }`}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="flex justify-center mt-4 gap-2">
        {currentPage > 1 && (
          <button
            className="px-3 py-1 rounded bg-gray-200 text-gray-800"
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            &laquo;
          </button>
        )}
        {paginationButtons}
        {currentPage < totalPages && (
          <button
            className="px-3 py-1 rounded bg-gray-200 text-gray-800"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            &raquo;
          </button>
        )}
      </div>
    );
  };

  // Handle category filter change
  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setSearchParams({ category: selectedCategory }); // Update query params
  };

  return (
    <>
    <MetaDecorator title="Produk - Viaduct Press" description="Viaduct Press menawarkan buletin, nada minor, dan selisip yang informatif. Kunjungi situs kami untuk mendapatkan wawasan terbaru dan produk unggulan."/>
    <div className="p-8">
      <div className="flex justify-between items-center mb-4">
        {/* Search Bar */}
        <input
          type="text"
          placeholder="Cari produk..."
          className="border px-4 py-2 rounded w-1/2 sm:w-1/3 shadow-md"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {/* Category Filter */}
        <select
          className="border px-4 py-2 rounded shadow-md"
          value={category}
          onChange={handleCategoryChange}
        >
          <option value="">Semua Kategori</option>
          <option value="Buletin">Buletin</option>
          <option value="Nada Minor">Nada Minor</option>
          <option value="Selisip">Selisip</option>
        </select>
      </div>

      {/* Product List */}
      {currentProducts.length === 0 ? (
        <div className="min-h-60 text-center py-8">
          <p className="text-gray-500">Belum ada produk dalam kategori ini.</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {currentProducts.map((product) => (
            <ProductCard
              key={product.id}
              id={product.id}
              title={product.title}
              image={product.coverImage}
            />
          ))}
        </div>
      )}

      {/* Pagination */}
      {renderPagination()}
    </div>
    </>
  );
};

export default Product;
