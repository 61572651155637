import React, {useState, useEffect} from "react";
import LatestCarousel from "../components/LatestCarousel";
import CategoryCarousel from "../components/CategoryCarousel";
import axios from "axios";
import MetaDecorator from "../components/MetaDecorator";

const HomePage = () => {
  const [blogs, setBlogs] = useState([]); // Full blog list
  const [filteredBlogs, setFilteredBlogs] = useState([]); // Blogs after filtering
  const blogsPerPage = 5; // Number of blogs per page

  // Simulate fetching blogs from a database
  useEffect(() => {
    const getBlogs = async () => {
      try {
        const response = await axios.get("https://api2.viaductpress.id/rubrik");
        setBlogs(response.data);
        setFilteredBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    getBlogs();
  }, []);
  const sortedBlogs = blogs
  .slice() // Create a copy to avoid mutating the original array
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const latestBlogs = sortedBlogs.slice(0, blogsPerPage);
  const categoryBlogs = sortedBlogs; // All blogs for category sections

  return (
    <>
    <MetaDecorator title="Viaduct Press – Jembatan Aspirasi Mahasiswa" description="Viaduct adalah platform yang menghubungkan aspirasi mahasiswa melalui rubrik dan produk yang informatif dan inovatif."/>
    <div className="p-8">
      {/* Latest Blogs */}
      <LatestCarousel blogs={latestBlogs} />

      {/* Category Blogs */}
      <CategoryCarousel
        blogs={categoryBlogs.filter((blog) => blog.categories.includes("Ragam")).slice(0,12)}
        category="Ragam"
      />
      <CategoryCarousel
        blogs={categoryBlogs.filter((blog) => blog.categories.includes("Dunia Hukum")).slice(0,12)}
        category="Dunia Hukum"
      />
      <CategoryCarousel
        blogs={categoryBlogs.filter((blog) => blog.categories.includes("Opini")).slice(0,12)}
        category="Opini"
      />
    </div>
    </>
  );
};

export default HomePage;
