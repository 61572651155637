import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import BlogCard from "../components/BlogCard";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import MetaDecorator from "../components/MetaDecorator";

const Blog = () => {
  let isMobile = useMediaQuery({ query: "(max-width: 480px), (max-height: 500px)" });
  const [blogs, setBlogs] = useState([]); // Full blog list
  const [filteredBlogs, setFilteredBlogs] = useState([]); // Blogs after filtering
  const [searchTerm, setSearchTerm] = useState(""); // Search input
  const [currentPage, setCurrentPage] = useState(1); // Current pagination page
  const blogsPerPage = 6; // Number of blogs per page
  const [searchParams, setSearchParams] = useSearchParams(); // For handling query parameters

  // Extract category from query params
  const category = searchParams.get("category") || "";

  // Simulate fetching blogs from a database
  useEffect(() => {
    const getBlogs = async () => {
      try {
        const response = await axios.get(`https://api2.viaductpress.id/rubrik`);
        setBlogs(response.data);
        setFilteredBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    getBlogs();
  }, []);

  // Apply search and filter whenever `category` or `searchTerm` changes
  useEffect(() => {
    let updatedBlogs = blogs;

    // Filter by category
    if (category) {
      updatedBlogs = updatedBlogs.filter((blog) =>
        blog.categories.includes(category)
      );
    }

    // Search by name/description
    if (searchTerm) {
      updatedBlogs = updatedBlogs.filter(
        (blog) =>
          blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (blog.authorName &&
            blog.authorName.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    setFilteredBlogs(updatedBlogs);
    setCurrentPage(1); // Reset to the first page on filter/search
  }, [category, searchTerm, blogs]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);

  // Pagination Logic
  const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const sortedBlogs = filteredBlogs
    .slice() // Create a copy to avoid mutating the original array
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const currentBlogs = sortedBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const renderPagination = () => {
    const maxVisiblePages = isMobile ? 3 : 5; // Maximum number of pages visible
    const pageNumbers = [];

    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <nav
        aria-label="Pagination"
        className="flex items-center justify-center border-t border-gray-200 bg-white px-4 sm:px-6"
      >
        <div className="flex flex-1 items-center justify-center">
          <div>
            <div className="isolate inline-flex -space-x-px rounded-md shadow-sm">
              {/* Previous Button */}
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                disabled={currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                <FaChevronLeft size={5} />
              </button>

              {/* Page Numbers */}
              {startPage > 1 && (
                <button
                  onClick={() => setCurrentPage(1)}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-20"
                >
                  1
                </button>
              )}
              {startPage > 2 && (
                <span className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700">
                  ...
                </span>
              )}
              {pageNumbers.map((page) => (
                <button
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
                    page === currentPage
                      ? "z-10 bg-black text-white"
                      : "text-gray-700 hover:bg-gray-200"
                  } focus:z-20`}
                >
                  {page}
                </button>
              ))}
              {endPage < totalPages - 1 && (
                <span className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700">
                  ...
                </span>
              )}
              {endPage < totalPages && (
                <button
                  onClick={() => setCurrentPage(totalPages)}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-20"
                >
                  {totalPages}
                </button>
              )}

              {/* Next Button */}
              <button
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                disabled={currentPage === totalPages}
              >
                <span className="sr-only">Next</span>
                <FaChevronRight size={5} />
              </button>
            </div>
          </div>
        </div>
      </nav>
    );
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setSearchParams({ category: selectedCategory }); // Update query params
  };

  return (
    <>
    <MetaDecorator title="Rubrik - Viaduct Press" description="Temukan rubrik menarik, inovatif, dan informatif yang mendukung ide-ide brilian Anda."/>
    <div className="p-8">
      <div className="flex justify-between items-center mb-4">
        {/* Search Bar */}
        <input
          type="text"
          placeholder="Cari rubrik..."
          className="border px-4 py-2 rounded w-1/2 sm:w-1/3 shadow-md"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {/* Category Filter */}
        <select
          className="border px-4 py-2 rounded shadow-md"
          value={category}
          onChange={handleCategoryChange}
        >
          <option value="">Semua Kategori</option>
          <option value="Ragam">Ragam</option>
          <option value="Dunia Hukum">Dunia Hukum</option>
          <option value="Dunia Kampus">Dunia Kampus</option>
          <option value="Opini">Opini</option>
          <option value="Sekitar Kita">Sekitar Kita</option>
          <option value="Jalan Jalan">Jalan Jalan</option>
          <option value="Lifestyle">Lifestyle</option>
          <option value="Ruang">Ruang</option>
          <option value="Ulasan">Ulasan</option>
          <option value="Puisi">Puisi</option>
          <option value="Cerpen">Cerpen</option>
          <option value="Resensi">Resensi</option>
          <option value="Serba Serbi">Serba Serbi</option>
          <option value="Ruang Tamu">Ruang Tamu</option>
          <option value="Profil">Profil</option>
        </select>
      </div>

      {/* Blog List */}
      {currentBlogs.length === 0 ? (
        <div className="min-h-60 text-center py-8">
          <p className="text-gray-500">Belum ada rubrik dalam kategori ini.</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {currentBlogs.map((blog) => (
            <BlogCard
              id={blog.id}
              title={blog.title}
              content={blog.content}
              image={blog.coverImage}
            />
          ))}
        </div>
      )}
      <div className="pb-12"></div>
      {/* Pagination */}
      {renderPagination()}
    </div>
    </>
  );
};

export default Blog;