import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

const LatestCarousel = ({ blogs }) => {
  // Initialize EmblaCarousel with the Autoplay plugin
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true},
    [Autoplay({ delay: 3600, stopOnInteraction: true, stopOnMouseEnter: true })]
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  // Callback to update the current index on slide change
  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  // Attach the callback to the Embla API
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on("select", onSelect);
    onSelect(); // Run the callback initially
  }, [emblaApi, onSelect]);

  // Buttons to navigate
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])
  

  return (
    <div className="relative max-w-full p-6 bg-gray-100">
      <h1 className="text-xl font-bold text-gray-700 mb-4">Rubrik Terbaru</h1>
      {/* Embla Carousel viewport */}
      <div
        className="embla embla_latest overflow-hidden rounded-lg shadow-lg border border-gray-300"
        ref={emblaRef}
      >
        <div className="embla__container embla__container_latest  flex">
          {blogs.map((blog, index) => (
            <div
              key={index}
              className="embla__slide embla__slide_latest  flex items-stretch w-full"
            >
              {/* Left side */}
              <div className="w-1/2 flex flex-col justify-center aspect-square items-start bg-white">
                <div className="flex flex-col justify-center items-start bg-white pl-6 pr-2 md:pl-16 md:pr-6 l:pl-16 l:pr-6 gap-2">
                  <p className="text-xs3 sm:text-xs2 md:text-xs lg:text-md xl:text-lg 2xl:text-xl text-black-500 underline">
                    {blog.categories.replace(/"/g, '')}
                  </p>
                  <a href={"/rubrik/"+blog.id} className="text-xs2 sm:text-xs md:text-sm lg:text-lg xl:text-xl 2xl:text-2xl font-semibold text-black">
                    {blog.title}
                  </a>
                  <p className="text-xs3 sm:text-xs2 md:text-xs lg:text-md xl:text-lg 2xl:text-xl text-gray-400 italic">
                    {new Date(blog.createdAt).toLocaleDateString('id', {month: 'long', day: '2-digit', year: 'numeric'})}
                  </p>
                </div>
              </div>
              {/* Right side */}
              <div className="w-1/2 aspect-square">
                <a href={"/rubrik/"+blog.id}><img
                  src={blog.coverImage}
                  alt={blog.title}
                  className="w-full h-full object-cover"
                /></a>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Navigation buttons */}
      <button
        className="carousel-btn absolute top-1/2 left-4 md:left-2 transform -translate-y-[calc(-50%)] md:-translate-y-[calc(-25%)] lg:-translate-y-1/2 w-6 h-6 md:w-12 md:h-12 flex justify-center items-center border-3 border-black bg-white cursor-pointer z-10"
        onClick={scrollPrev}
        aria-label="Previous Slide"
      >
        &#10094;
      </button>
      <button
        className="carousel-btn absolute top-1/2 right-4 md:right-2 transform -translate-y-[calc(-50%)] md:-translate-y-[calc(-25%)] lg:-translate-y-1/2 w-6 h-6 md:w-12 md:h-12 flex justify-center items-center border-3 border-black bg-white cursor-pointer z-10"
        onClick={scrollNext}
        aria-label="Next Slide"
      >
        &#10095;
      </button>
    </div>
  );
};

export default LatestCarousel;
