import React, { useCallback, useEffect, useState } from "react";
import CarouselCard from "./CarouselCard";
import useEmblaCarousel from 'embla-carousel-react';

const CategoryCarousel = ({ blogs, category }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
      slidesToScroll: 2,
      align: "start"
    }
  );

  // Callback to update the current index on slide change
  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  // Attach the callback to the Embla API
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on("select", onSelect);
    onSelect(); // Run the callback initially
  }, [emblaApi, onSelect]);

  // Buttons to navigate
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  return (
    <div className="my-8 relative px-5">
      <h3 className="text-xl font-bold text-gray-700 mb-4">{category}</h3>
      <section className="embla embla_category relative">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container embla__container_category">
            {blogs.map((blog, index) => (
              <div
                key={index}
                className="embla__slide embla__slide_category flex w-1/2"
              >
                <CarouselCard
                  id={blog.id}
                  coverImage={blog.coverImage}
                  category={blog.categories.replace(/"/g, '')}
                  title={blog.title}
                  createdAt={blog.createdAt}
                />
              </div>
            ))}
          </div>
        </div>
        {/* Navigation buttons */}
        <button
          className="carousel-btn absolute top-1/2 left-0 transform -translate-y-1/2 -translate-x-1/2 w-6 h-6 md:w-12 md:h-12 flex justify-center items-center bg-white shadow-md cursor-pointer z-10"
          onClick={scrollPrev}
          aria-label="Previous Slide"
        >
          &#10094;
        </button>
        <button
          className="carousel-btn absolute top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2 w-6 h-6 md:w-12 md:h-12 flex justify-center items-center bg-white shadow-md cursor-pointer z-10"
          onClick={scrollNext}
          aria-label="Next Slide"
        >
          &#10095;
        </button>
      </section>
    </div>
  );
  
};

export default CategoryCarousel;
