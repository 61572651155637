import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import MetaDecorator from "../components/MetaDecorator";

const Submit = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    name: "",
    content: "",
    categories:[],
    email: "",
    whatsapp: "",
    instagram: "",
    file: null,
    consent: false,
  });

  const [errors, setErrors] = useState({});
  const [preview, setPreview] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const categoryOptions = [
    { value: "Ragam", label: "Ragam" },
    { value: "Dunia Hukum", label: "Dunia Hukum" },
    { value: "Dunia Kampus", label: "Dunia Kampus" },
    { value: "Opini", label: "Opini" },
    { value: "Sekitar Kita", label: "Sekitar Kita" },
    { value: "Jalan Jalan", label: "Jalan Jalan" },
    { value: "Lifestyle", label: "Lifestyle" },
    { value: "Ruang", label: "Ruang" },
    { value: "Ulasan", label: "Ulasan" },
    { value: "Puisi", label: "Puisi" },
    { value: "Cerpen", label: "Cerpen" },
    { value: "Resensi", label: "Resensi" },
    { value: "Serba Serbi", label: "Serba Serbi" },
    { value: "Ruang Tamu", label: "Ruang Tamu" },
    { value: "Profil", label: "Profil" },
  ];
  
  // Handle form field changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle file upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      file: file,
    }));
    try{
      setPreview(URL.createObjectURL(file));
    }catch (err){
      setPreview("")
    }
  };

  const handleCategoryChange = (selectedOptions) => {
    setFormData((prevData) => ({
      ...prevData,
      categories: selectedOptions || [],
    }));
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    console.log(formData.categories.map((cat) => cat.value).join(", "));
    // Validate form data
    if (!formData.title.trim()) newErrors.title = "Judul dibutuhkan.";
    if (!formData.name.trim()) newErrors.title = "Nama dibutuhkan.";
    if (!formData.content.trim()) newErrors.content = "Konten dibutuhkan.";
    if (!formData.email.trim() && !formData.whatsapp.trim() && !formData.instagram.trim()) {
      newErrors.email = "Minimal satu kontak dibutuhkan";
      newErrors.whatsapp = "Minimal satu kontak dibutuhkan";
      newErrors.instagram = "Minimal satu kontak dibutuhkan";
    } else {
      // Validate email if provided
      if (formData.email.trim() && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        newErrors.email = "Email tidak valid.";
      }
    
      // Validate WhatsApp if provided
      if (formData.whatsapp.trim() && !/^\+?62\d{9,13}$/.test(formData.whatsapp)) {
        newErrors.whatsapp = "Nomor Whatsapp harus valid (format Indonesia, contoh: +6281234567890).";
      }
    }
    if (!formData.categories || formData.categories.length === 0) {
      newErrors.categories = "Minimal satu kategori harus dipilih.";
    }
    if (!formData.consent) newErrors.consent = "Anda perlu menyetujui persetujuan.";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitting(true);
      
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("authorName", formData.name);
      formDataToSend.append("content", formData.content);
      formDataToSend.append("authorEmail", formData.email);
      formDataToSend.append("authorWhatsapp", formData.whatsapp);
      formDataToSend.append("authorInstagram", formData.instagram);
      formDataToSend.append("categories", formData.categories.map((cat) => cat.value).join(","));
      formDataToSend.append("file", formData.file);
      // Send data to backend (e.g., using fetch or axios)
      try {
        const response = await axios.post("https://api2.viaductpress.id/rubrik", formDataToSend, {
          headers:{
            "Content-Type": "multipart/form-data"
          }
        });
        console.log("Blog submitted successfully:", response);
        alert("Blog submitted successfully.");
        navigate("/");
      } catch (err) {
        console.error("Error submitting blog:", err);
        alert("Error submitting blog.");
        console.log(formDataToSend);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
    <MetaDecorator title={"Kirim Tulisan - Viaduct Press"} description="Apakah Anda memiliki ide brilian? Tulis dan bagikan karya Anda melalui Viaduct Press dan temukan pembaca yang menghargai setiap kata yang Anda tulis."/>
    <div className="p-8 max-w-2xl mx-auto">
      <h1 className="text-2xl font-bold text-center mb-6">Kirim Tulisan Anda</h1>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Title */}
        <div>
          <label htmlFor="title" className="block font-medium mb-1">Judul Blog</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded shadow-md"
            placeholder="Judul Blog"
          />
          {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
        </div>

        {/* Categories */}
        <div>
          <label htmlFor="categories" className="block font-medium mb-1">
            Kategori
          </label>
          <Select
            isMulti
            id="categories"
            name="categories"
            options={categoryOptions}
            className="basic-multi-select mt-2"
            classNamePrefix="select"
            onChange={handleCategoryChange}
            placeholder="Pilih kategori (min. 1)"
          />
          {errors.categories && <p className="text-red-500 text-sm mt-1">{errors.categories}</p>}
        </div>

        {/* Content */}
        <div>
          <label htmlFor="content" className="block font-medium mb-1">Konten Blog</label>
          <textarea
            id="content"
            name="content"
            value={formData.content}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded shadow-md"
            rows="6"
            placeholder="Tuliskan isi blog Anda di sini..."
          />
          {errors.content && <p className="text-red-500 text-sm mt-1">{errors.content}</p>}
        </div>

        {/* Name */}
        <div>
          <label htmlFor="name" className="block font-medium mb-1">Nama Lengkap</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded shadow-md"
            placeholder="Tuliskan nama lengkap Anda.."
          />
          {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
        </div>

        {/* Email */}
        <div>
          <label htmlFor="email" className="block font-medium mb-1">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded shadow-md"
            placeholder="Email Anda"
          />
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
        </div>

        {/* Whatsapp */}
        <div>
          <label htmlFor="whatsapp" className="block font-medium mb-1">Nomor WhatsApp</label>
          <input
            type="text"
            id="whatsapp"
            name="whatsapp"
            value={formData.whatsapp}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded shadow-md"
            placeholder="Nomor Whatsapp Anda dalam format Indonesia, contoh: +6281234567890"
          />
          {errors.whatsapp && <p className="text-red-500 text-sm mt-1">{errors.whatsapp}</p>}
        </div>

        {/* Instagram */}
        <div>
          <label htmlFor="instagram" className="block font-medium mb-1">Instagram</label>
          <input
            type="text"
            id="instagram"
            name="instagram"
            value={formData.instagram}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded shadow-md"
            placeholder="Username Instagram Anda"
          />
          {errors.instagram && <p className="text-red-500 text-sm mt-1">{errors.instagram}</p>}
        </div>

        {/* File Upload */}
        <div>
          <label htmlFor="file" className="block font-medium mb-1 ">Gambar Cover (PNG, JPG, atau JPEG)</label>
          <input
            type="file"
            id="file"
            name="file"
            accept=".png,.jpg,.jpeg"
            onChange={handleFileChange}
            className="w-full px-4 py-2 border rounded"
          />
        </div>

        {preview ? (
          <div className="container bg-gray">
          <p className="text-gray-800 block font-medium">Preview Gambar</p>
          <img
            src={preview}
            alt="Blog"
            className="w-full h-96 object-cover rounded-lg shadow-md"
          /></div>
        ): (
          ""
        )}

        {/* Consent Checkbox */}
        <div>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="consent"
              checked={formData.consent}
              onChange={handleChange}
              className="mr-2"
            />
            <span>Dengan mengirimkan tulisan anda setuju bahwa tulisan yang anda kirim dapat saja tidak dipublikasi karena alasan tertentu atau dilakukan penyuntingan untuk memenuhi syarat-syarat dan kebijakan redaksional oleh tim redaksi Viaduct.
            </span>
          </label>
          {errors.consent && <p className="text-red-500 text-sm mt-1">{errors.consent}</p>}
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-black text-white px-4 py-2 hover:bg-slate-900"
          >
            {isSubmitting ? "Mengirim..." : "Kirim"}
          </button>
        </div>
      </form>
    </div>
    </>
  );
};

export default Submit;
