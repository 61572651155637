import React from "react";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import Logo from '../assets/logo.png'
import { Link} from 'react-router-dom'
import { useMediaQuery } from "react-responsive";

const sections = [
    {
      title: "Home",
      path: "/",
      items: [], // No sub-items for Home
    },
    {
      title: "Rubrik",
      path: "/rubrik",
      items: [
        { title: "Ragam", sub_path: "Ragam"},
        { title: "Dunia Hukum", sub_path: "Dunia+Hukum"},
        { title: "Dunia Kampus", sub_path: "Dunia+Kampus"},
        { title: "Opini", sub_path: "Opini"},
        { title: "Sekitar Kita", sub_path: "Sekitar+Kita"},
        { title: "Jalan Jalan", sub_path: "Jalan+Jalan"},
        { title: "Lifestyle", sub_path: "Lifestyle"},
        { title: "Ruang", sub_path: "Ruang"},
        { title: "Ulasan", sub_path: "Ulasan"},
        { title: "Puisi", sub_path: "Puisi"},
        { title: "Cerpen", sub_path: "Cerpen"},
        { title: "Resensi", sub_path: "Resensi"},
        { title: "Serba Serbi", sub_path: "Serba+Serbi"},
        { title: "Ruang Tamu", sub_path: "Ruang+Tamu"},
        { title: "Profil", sub_path: "Profil"},
      ],
    },
    {
      title: "Produk",
      path: "/produk",
      items: [
        { title: "Buletin", sub_path: "Buletin"},
        { title: "Nada Minor", sub_path: "Nada+Minor"},
        { title: "Selisip", sub_path: "Selisip"},
      ],
    },
    {
      title: "Kirim Tulisan",
      path: "/kirim-tulisan",
      items: [], // No sub-items for Kirim Tulisan
    },
    {
      title: "Tentang Kami",
      path: "/tentang-kami",
      items: [], // No sub-items for Tentang Kami
    },
  ];

const items = [
  { name: "Twitter", icon: FaXTwitter, link: "https://x.com/viaductpress" },
  { name: "Facebook", icon: FaFacebook, link: "https://www.facebook.com/ViaductPersMahasiswa" },
  { name: "Instagram", icon: FaInstagram, link: "https://www.instagram.com/viaductpress" },
  
];

const Footer = () => {
  let isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <>
      {/* DEKSTOP FOOTER */}
      {!isTabletMid && (
      <div className="w-full mt-24 bg-neutral-900 text-gray-300 px-2">
      
        <div className="max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-5 pr-5 border-b-2 border-gray-600 py-8 text-right">
          {sections.map((section, index) => (
            <div key={index}>
              <Link to={section.path}><h6 className="font-bold uppercase pt-2">{section.title}</h6></Link>
              <ul>
              {section.items.map((item, i) => (
                  <li key={i} className="py-1 text-gray-500 hover:text-white">
                    <Link to={section.path+"?category="+item.sub_path}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
          <div className="flex flex-col items-center">
              <img src={Logo} alt="Logo" className="w-40 rounded-md" />
              <p className="py-4">2024 Viaduct. All rights reserved</p>
          </div>
          <div className="flex justify-between sm:w-[300px] pt-4 px-4 text-2xl items-center">
            {items.map((item) => (
              <Link to={item.link} className="hover:text-gray-500">
                <item.icon/>
              </Link>
           ))}
          </div>
        </div>
      </div>
    )}

    {/* MOBILE FOOTER */}
    {isTabletMid && (
       <div className="w-full mt-24 bg-neutral-900 text-gray-300 px-2">
      
       <div className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-5 border-b-2 border-gray-600 pt-6 pb-4 text-center">
         {sections.map((section, index) => (
           <div key={index}>
             <Link to={section.path}><h6 className="font-bold uppercase pt-2">{section.title}</h6></Link>
           </div>
         ))}
       </div>

       <div className="flex flex-col max-w-[1240px] px-4 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
         <div className="flex flex-col items-center">
             <img src={Logo} alt="Logo" className="w-40 rounded-md" />
             <p className="py-4">2024 Viaduct. All rights reserved</p>
         </div>
         <div className="flex justify-center sm:w-[300px] gap-5 pt-4 px-4 pb-6 text-2xl items-center">
           {items.map((item) => (
              <Link to={item.link} className="hover:text-gray-500">
                <item.icon />
              </Link>
           ))}
         </div>
       </div>
     </div>
    )}
  </>
  );
};

export default Footer;