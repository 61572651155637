const CarouselCard = ({ id, coverImage, category, title, createdAt }) => {
    return (
      <div className="bg-white shadow-xl w-full">
        <a href={"/rubrik/"+id}><img src={coverImage} alt={title} className="w-full aspect-square object-cover" /></a>
        <div className="p-4">
          <p className="text-xs3 sm:text-xs2 md:text-xs lg:text-sm xl:text-md 2xl:text-lg text-black-500 underline">{category}</p>
          <a href={"/rubrik/"+id} className="text-xs2 sm:text-xs md:text-sm lg:text-md xl:text-l 2xl:text-xl font-semibold text-gray-800">{title}</a>
          <p className="text-xs3 sm:text-xs2 md:text-xs lg:text-sm xl:text-md 2xl:text-lg text-gray-500 mt-1">{new Date(createdAt).toLocaleDateString('id', {month: 'long', day: '2-digit', year: 'numeric'})}</p>
        </div>
      </div>
    );
  };
  
  export default CarouselCard;
  