import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";
import { MdMenuOpen } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { ImBlog } from "react-icons/im";
import { FaPeopleGroup } from "react-icons/fa6";
import { IoIosSend } from "react-icons/io";
import { FaBook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const menuItems = [
  {
    icons: <FaHome size={24} />,
    label: "Home",
    path: "/",
  },
  {
    icons: <ImBlog size={24} />,
    label: "Rubrik",
    path: "/rubrik",
  },
  {
    icons: <FaBook size={24} />,
    label: "Produk",
    path: "/produk",
  },
  {
    icons: <IoIosSend size={24} />,
    label: "Kirim Tulisan",
    path: "/kirim-tulisan",
  },
  {
    icons: <FaPeopleGroup size={24} />,
    label: "Tentang Kami",
    path: "/tentang-kami",
  },
];

export default function MobileNavbar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      {/* Mobile Navbar Header */}
      <div className="flex items-center justify-between bg-black text-white px-4 py-3">
        {/* Hamburger Icon */}
        <MdMenuOpen
          size={30}
          className="cursor-pointer"
          onClick={toggleSidebar}
        />
        {/* Logo */}
        <Link to="/">
          <img src={Logo} alt="Logo" className="w-20" />
        </Link>
      </div>

      {/* Sidebar Overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 z-40 ${
          isSidebarOpen ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        onClick={toggleSidebar}
      ></div>

      {/* Sidebar Menu */}
      <div
        className={`fixed top-0 left-0 h-full bg-black text-white z-50 w-64 transform transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {/* Close Button */}
        <div className="flex justify-between items-center px-4 py-3 border-b border-gray-700">
          <h2 className="text-lg font-semibold">Menu</h2>
          <MdMenuOpen
            size={30}
            className="cursor-pointer"
            onClick={toggleSidebar}
          />
        </div>

        {/* Navigation Links */}
        <ul className="mt-4">
          {menuItems.map((item, index) => (
            <Link to={item.path} key={index} onClick={toggleSidebar}>
              <li className="flex items-center gap-4 px-4 py-3 hover:bg-gray-800 cursor-pointer">
                {item.icons}
                <span>{item.label}</span>
              </li>
            </Link>
          ))}
        </ul>
        
        <div className={"text-white flex flex-row space-x-4 gap-4 justify-center p-4 mt-5"}>
            <Link to="https://x.com/viaductpress" className="hover:text-gray-400">
                <FaXTwitter size={20} />
            </Link>
            <Link to="https://www.facebook.com/ViaductPersMahasiswa" className="hover:text-gray-400">
                <FaFacebook size={20} />
            </Link>
            <Link to="https://www.instagram.com/viaductpress/" className="hover:text-gray-400">
                <FaInstagram size={20} />
            </Link>
        </div>

      </div>
    </>
  );
}
