import React from 'react';

const BlogCard = ({ id, title, content, image }) => {
  const regex = /(<([^>]+)>)/gi
  return (
    <div class="relative flex w-full flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md item">
        <img class="h-80 w-full object-cover" src={image} alt="Blog Cover"/>
      <div class="mt-4 px-5 pb-1">
        <div class="font-bold text-xl mb-2">{title}</div>
      </div>
      <div class="mt-auto px-5 pb-1">
        <p class="truncated-text text-md mb-2">{content.replace(regex, "")}</p>
      </div>
      <div class="mt-auto px-6 pb-4">
        <a className="flex items-center justify-center bg-black px-8 py-3 text-center text-sm font-medium text-white hover:bg-neutral-700" href={"/rubrik/"+id}>Baca Lebih</a>
      </div>
  </div>
  );
};

export default BlogCard;