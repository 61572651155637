import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo.png";

// icons
import { IconContext } from "react-icons";
import { MdMenuOpen } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { ImBlog } from "react-icons/im";
import { FaPeopleGroup } from "react-icons/fa6";
import { IoIosSend } from "react-icons/io";
import { FaBook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const menuItems = [
  {
    icons: <FaHome size={28} />,
    label: "Home",
    path: "/",
  },
  {
    icons: <ImBlog size={28} />,
    label: "Rubrik",
    path: "/rubrik",
  },
  {
    icons: <FaBook size={28} />,
    label: "Produk",
    path: "/produk",
  },
  {
    icons: <IoIosSend size={28} />,
    label: "Kirim Tulisan",
    path: "/kirim-tulisan",
  },
  {
    icons: <FaPeopleGroup size={28} />,
    label: "Tentang Kami",
    path: "/tentang-kami",
  },
];

export default function Sidebar() {
  const [open, setOpen] = useState(true);
  const location = useLocation(); // Get the current route

  return (
    <nav
      className={`shadow-md h-screen p-2 flex flex-col duration-500 bg-black sticky top-0 overflow-visible text-white ${
        open ? "w-80" : "w-16"
      } z-10`}
    >
      {/* Header */}
      <div className="px-2 py-2 h-20 flex justify-between items-center">
        <Link to="/">
          <img
            src={Logo}
            alt="Logo"
            className={`${open ? "w-40" : "w-0"} rounded-md`}
          />
        </Link>
        <div>
          <MdMenuOpen
            size={34}
            className={`duration-500 cursor-pointer ${!open && "rotate-180"}`}
            onClick={() => setOpen(!open)}
          />
        </div>
      </div>

      {/* Menu Items */}
      <ul className="flex-1 mt-10">
        {menuItems.map((item, index) => {
          const isActive = location.pathname === item.path; // Check if the menu item is active
          return (
            <Link to={item.path} key={index}>
              <li
                className={`${
                  open ? "px-3" : "px-2"
                } py-2 my-4  ${isActive ? "" : "hover:bg-neutral-800"} rounded-md duration-300 cursor-pointer flex gap-2 items-center relative group li-cust ${
                  isActive ? "text-gray-400 underline" : "text-white"
                }`}
              >
                  <IconContext.Provider value={{ color: `${isActive ? "gray" : "white"}`, className: "h-full" }}>
                    <div>{item.icons}</div>
                  </IconContext.Provider>
                <p
                  className={`${
                    !open && "w-0 translate-x-24"
                  } duration-500 overflow-hidden`}
                >
                  {item.label}
                </p>
                {/* Hover Label */}
                <p
                  className={`${
                    open && "hidden"
                  } absolute left-16 shadow-md rounded-md whitespace-nowrap z-20
              bg-black text-white px-2 py-1 opacity-0 group-hover:opacity-100 group-hover:translate-x-0 duration-200`}
                >
                  {item.label}
                </p>
              </li>
            </Link>
          );
        })}
      </ul>

      {/* Footer */}
      <div
        className={`text-white flex ${
          !open
            ? "flex-col items-center pb-5 space-y-5"
            : "flex-row space-x-4 gap-2 justify-center"
        } p-4`}
      >
        <Link to="https://x.com/viaductpress" className="hover:text-gray-400">
          <FaXTwitter size={20} />
        </Link>
        <Link
          to="https://www.facebook.com/ViaductPersMahasiswa"
          className="hover:text-gray-400"
        >
          <FaFacebook size={20} />
        </Link>
        <Link
          to="https://www.instagram.com/viaductpress/"
          className="hover:text-gray-400"
        >
          <FaInstagram size={20} />
        </Link>
      </div>
    </nav>
  );
}
