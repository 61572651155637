import React from 'react'

const ErrorPage = () => {
  return (
  <div class="h-screen w-screen bg-gray-100 flex items-center">
    <div class="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
        <div class="max-w-md">
            <div class="text-5xl font-dark font-bold">404</div>
              <p
                class="text-2xl md:text-3xl font-light leading-normal"
              >Maaf kami tidak dapat menemukan halaman ini.</p>
            <p class="mb-8">Tenang, Anda dapat menemukan banyak hal lain di Viaduct.</p>
            
            <a class="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent focus:outline-none focus:shadow-outline-blue bg-black active:bg-gray-300 active:text-black hover:bg-neutral-700" href="/">Kembali ke Viaduct</a>
      </div>
    </div>
  </div>
  )
}

export default ErrorPage