import React, {useLayoutEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import ErrorPage from './pages/ErrorPage';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import Product from './pages/Product';
import ProductDetail from './pages/ProductDetail';
import About from './pages/About';
import Submit from './pages/Submit';

const Wrapper = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location.pathname, location.search]);

  return children;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Wrapper><Layout/></Wrapper>,
    errorElement: <ErrorPage/>,
    children:[
      {index: true, element: <Home/>},
      {path: "rubrik", element:<Blog/>},
      {path: "rubrik/:id", element:<BlogDetail/>},
      {path: "produk", element:<Product/>},
      {path: "produk/:id", element:<ProductDetail/>},
      {path: "tentang-kami", element:<About/>},
      {path: "kirim-tulisan", element:<Submit/>}

    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router}/>
  </React.StrictMode>
);

